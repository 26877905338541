

























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';
import Media from 'vue-media';

import { Extension } from '../store/types';

import Modal from '@/components/Modal.vue';

interface NewExtension {
  name: string;
  extension_id: string;
  extension_secret: string;
}

Component.registerHooks(['beforeRouteEnter']);

@Component({
  name: 'Dashboard',
  components: { Modal, Media }
})
export default class Dashboard extends Vue {
  @Prop()
  public dash!: boolean;

  @Prop()
  public launchAddExtension: boolean;

  public ownedExtensions: Extension[] = [];
  public adminExtensions: Extension[] = [];
  public showAddExtensionModal: boolean = false;
  public newExtension: NewExtension;
  public addExtensionError: string = '';

  public beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    next((vm: Vue) => {
      if (!vm.$auth?.user) {
        next({ name: from.name! });
      } else {
        next();
      }
    });
  }

  public mounted() {
    if (this.$auth?.user) {
      this.loadExtensions();
    }

    if (this.launchAddExtension) {
      this.showAddExtension();
    }
  }

  public loadExtensions() {
    this.$api.get(`/api/extensions`).then<any>((response) => {
      if (this.dash) {
        this.ownedExtensions = response.data.owned_extensions.slice(0, 5);
        this.adminExtensions = response.data.admin_extensions.slice(0, 5);
      } else {
        this.ownedExtensions = response.data.owned_extensions;
        this.adminExtensions = response.data.admin_extensions;
      }
    });
  }

  public setExtension(ext: Extension) {
    this.$router.push({ name: 'manage', params: { extension_id: ext.extension_id } });
  }

  public showAddExtension() {
    this.newExtension = {} as NewExtension;
    this.showAddExtensionModal = true;
    this.addExtensionError = '';
  }

  public addExtension() {
    this.addExtensionError = '';

    this.$api
      .post('/api/extensions/add', this.newExtension)
      .then<any>((response) => {
        this.loadExtensions();
        this.showAddExtensionModal = false;
      })
      .catch((error) => {
        if (error.response) {
          this.addExtensionError = error.response.data.error;
        } else {
          this.addExtensionError = 'Something went wrong, try again.';
        }
      });
  }

  public closeModal() {
    this.showAddExtensionModal = false;
  }
}
