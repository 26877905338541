import Vue, { CreateElement } from 'vue';

import VueAuthToken from '@/shared/vue_auth_token';

import App from './App.vue';
import router from './router';
import store from './store';

import '@/shared/scss/elevation.scss';

(async () => {
  Vue.config.productionTip = process.env.NODE_ENV === 'production';
  Vue.use(await VueAuthToken());

  new Vue({
    router,
    store,
    render: (h: CreateElement) => h(App)
  }).$mount('#app');
})();
