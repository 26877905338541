
























































import { Vue, Component, Prop } from 'vue-property-decorator';

// This component is used by the server-side rendered doc pages,
// so it must conform to the VueJS Universal Component Guides:
// https://ssr.vuejs.org/guide/universal.html

@Component
export default class Navbar extends Vue {
  @Prop({ default: false })
  public showSidebarToggle!: boolean;

  private clientSide: boolean = false;
  private showOptions: boolean = false;

  mounted() {
    this.clientSide = true;
  }

  private get user() {
    return this.$auth?.user;
  }

  public toggle() {
    this.showOptions = !this.showOptions;
    window.removeEventListener('click', this.toggle);

    if (this.showOptions) {
      window.addEventListener('click', this.toggle);
    }
  }
}
