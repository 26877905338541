






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Card extends Vue {
  @Prop({default: ""})
  public linkTo: string;

  get linkTarget() {
    return this.linkTo.includes("http") ? "_blank" : "";
  }
}
