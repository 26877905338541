import Vue from "vue";
import Vuex from "vuex";

import { DebugState } from "@/store/debug";

Vue.use(Vuex);

export interface RootState {
  debug: DebugState;
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<RootState>({});
