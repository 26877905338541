import _Vue from 'vue';

import axios, { AxiosInstance } from 'axios';
import jwt_decode from 'jwt-decode';

export class AuthTokenOptions {
  public token?: string;
}

export default async () => {
  let auth: any = null;
  let api = axios.create();

  try {
    const resp = await fetch('/api/auth');
    const csrf = await resp.json();

    // On empty response, this will throw an error.
    const user = jwt_decode(csrf || '');

    auth = {
      csrf,
      user
    };

    api = axios.create({
      headers: {
        common: {
          'X-Muxy-CSRF': csrf
        }
      }
    });
  } catch (err) {
    // User is not logged in.
    auth = {
      csrf: '',
      user: null,
      token: null,
    };
  }

  return (Vue: typeof _Vue) => {
    Vue.prototype.$auth = auth;
    Vue.prototype.$api = api;
  };
};

declare module 'vue/types/vue' {
  interface Vue {
    $auth: { csrf: string; user: any } | null;
    $api: AxiosInstance;
  }
}
