
































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'modal'
})
export default class Modal extends Vue {
  $refs: {
    container: HTMLElement;
  }

  created() {
    window.addEventListener('keydown', (e) => {
      if (e.key == 'Escape') {
        this.$emit('close');
      }
    });
  }

  public mounted() {
    document.body.classList.add("has-modal");

    const topPosition = window.scrollY + (window.innerHeight / 10);
    this.$refs.container.style.marginTop = `${topPosition}px`;
  }

  public beforeDestroy() {
    document.body.classList.remove("has-modal");
  }
}
