














import { Vue, Component } from 'vue-property-decorator';

import momenttz from 'moment-timezone';

import { User } from '@/store/types';

import Login from '@/components/Login.vue';
import MEDKitFooter from '@/components/MEDKitFooter.vue';
import Navbar from '@/components/Navbar.vue';

// ChartJS does not have a way to set a chart's display timezone, but moment is used internally, so setting its default
// TZ is a workaround (https://github.com/chartjs/Chart.js/issues/5186#issuecomment-391415588).
momenttz.tz.setDefault('UTC');

@Component({
  name: 'app',
  components: { Login, MEDKitFooter, Navbar }
})
export default class App extends Vue {}
