import Vue from 'vue';
import Router from 'vue-router';

import About from './views/About.vue';
import Dashboard from './views/Dashboard.vue';
import Landing from './views/Landing.vue';
import PageNotFound from './views/PageNotFound.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  
  routes: [
    {
      path: '/',
      redirect: '/landing'
    },
    {
      path: '/landing',
      name: 'landing',
      component: Landing
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard
    },
    {
      path: '/dashboard/add',
      name: 'addExtension',
      component: Dashboard,
      props: { launchAddExtension: true }
    },
    {
      path: '/extensions',
      name: 'extensions',
      component: () => import('./views/Extensions.vue')
    },
    {
      path: '/extensions/:extension_id',
      name: 'extension_info',
      component: () => import('./views/Extensions.vue'),
      children: [
        {
          path: 'manage',
          name: 'manage',
          component: () => import('./views/ExtensionManager.vue')
        },
        {
          path: 'analytics',
          name: 'analytics',
          component: () => import('./views/ExtensionInfo.vue')
        }
      ]
    },
    {
      path: '/admin/:extension_id',
      name: 'admin',
      component: () => import('./views/Admin.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue')
    },
    { path: '*', 
      component: PageNotFound 
    }
  ],

  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
});
