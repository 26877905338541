


































































import { Vue, Component } from 'vue-property-decorator';

import Card from '@/components/Card.vue';
import Dashboard from '@/views/Dashboard.vue';
import Navbar from '@/components/Navbar.vue';

import ArchitectureImage from '@/shared/images/architecture.png';
import GameLinkImage from '@/shared/images/gamelink.png';
import QuickstartImage from '@/shared/images/quickstart.png';

@Component({
  components: { Card, Dashboard, Navbar }
})
export default class Landing extends Vue {
  private showTOC = true;

  private get user() {
    return this.$auth?.user;
  }
}
