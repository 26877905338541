







































































































import { Vue, Component } from 'vue-property-decorator';


@Component
export default class MEDKitFooter extends Vue {
  private showOptions: boolean = false;

  private get user() {
    return this.$auth?.user;
  }

  public toggle() {
    this.showOptions = !this.showOptions;
    window.removeEventListener('click', this.toggle);

    if (this.showOptions) {
      window.addEventListener('click', this.toggle);
    }
  }

  private get year() {
    return new Date().getFullYear();
  }
}
